div.page-self-checkout-landscape {
	position:relative;
	width:100%;
	height:100vh;
}

div.page-self-checkout-landscape div.top-header {
	float:left; 
	width:100%;}
div.page-self-checkout-landscape div.top-header a {
	float:left; 
	text-decoration:none; 
	font-size:15px; 
	margin-top:5px; 
	color: rgb(13, 13, 13);
	display:flex; 
	align-items:center; 
	align-content:center;
}
div.page-self-checkout-landscape div.top-header a svg {
	float:left; 
	margin-right:0px; 
	margin-right:-10px; 
	margin-top:2px;
}