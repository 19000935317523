button.btn-zaitt {
	width:511px;
	height:93px;
	color:#161922;
	text-decoration:none;
	font-weight:bold;
	border-radius:52px;
	background: #DCE169 0% 0% no-repeat padding-box;
	box-shadow: 0px 5px 6px #0000004D;
	border:none;
	font: normal normal bold 32px/38px "exo soft semi bold";
}

@media only screen and (max-height: 1460px) {
	button.btn-zaitt {
		width: 480px;
		height: 70px;
		font: normal normal bold 26px/30px "exo soft semi bold";
	}
}