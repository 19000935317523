.progress-div {
	position:fixed;
	width:100%;
	height:4px;
	bottom:0px;
	left:0px;
	z-index:200;
    background-color: rgb(233, 233, 233);
    border-radius: .5rem;
}

.bar-time-operation {
	background-color: #42abad;
	height:4px;
	border-top-right-radius: 1rem;
	transition: 1s ease;
	transition-delay: 0.5s;
}