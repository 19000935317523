div.page-finish-payment-portrait {
  position: relative;
  max-width: 1106px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.page-finish-payment-portrait h1.title-finish {
  float: left;
  width: 100%;
  font-size: 130px;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent2;
}
div.page-finish-payment-portrait img.image-info-voucher {
  float: left;
  width: 80%;
  margin-bottom: 100px;
  margin-top: 40px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent3;
}
div.page-finish-payment-portrait p.msg-purchase-successfully {
  float: left;
  width: 100%;
  font-size: 48px;
  text-align: center;
  margin-bottom: 0px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent4;
}
div.page-finish-payment-portrait label.msg-obs {
  float: left;
  width: 100%;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  font-family: "exo soft bold";
  animation-duration: 1s;
  animation-name: showLeftToRightComponent5;
}
div.page-finish-payment-portrait h1.msg-thanks {
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent6;
}
div.page-finish-payment-portrait h1.msg-thanks span {
  color: #ffffff;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent7;
}

@media only screen and (max-height: 1500px) {
  div.page-finish-payment-portrait img.logo-portrait {
    width: 500px;
    height: 500px;
  }

  div.page-finish-payment-portrait h1.title-finish {
    margin-top: -50px;
    font-size: 105px;
  }

  div.page-finish-payment-portrait img.image-info-voucher {
    width: 70%;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}
