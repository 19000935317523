@font-face {
  font-family: exo soft regular;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Regular.otf");
}

@font-face {
  font-family: exo soft medium;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Medium.otf");
}

@font-face {
  font-family: exo soft semi bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-BoldSemiCn.otf");
}

@font-face {
  font-family: exo soft bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Bold.otf");
}

@font-face {
  font-family: exo soft italic bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-ExtraBoldIt.otf");
}

div.page-home-portrait {
  max-width: 1106px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
}

div.page-home-portrait img.logo-portrait {
  margin-top: 130px;
  margin-bottom: 20px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}

h1.msg-welcome-portrait {
  width: 100%;
  text-align: center;
  color: #1f202d;
  font: 900 88px/106px "Exo Soft italic bold";
  margin-bottom: 30px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent2;
}

h2.msg-first-smart-store-portrait {
  width: 729px;
  color: #ffffff;
  font: normal normal bold 56px/70px exo soft bold;
  text-align: center;
  margin-bottom: 60px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent3;
}

a.btn-init-portrait {
  animation-duration: 1s;
  animation-name: showLeftToRightComponent4;
}

h3.msg-click-button-portrait {
  width: 729px;
  text-align: center;
  font: 44px/52px "exo soft medium";
  letter-spacing: 0px;
  color: #1f202d;
  margin-top: 90px;
  margin-bottom: 130px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent5;
}

@media only screen and (max-height: 1500px) {
  div.page-home-portrait img.logo-portrait {
    margin: 0px;
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-height: 1320px) {
  div.page-home-portrait {
    max-width: 800px;
  }

  h1.msg-welcome-portrait {
    font: 900 66px/94px "Exo Soft italic bold";
  }

  h2.msg-first-smart-store-portrait {
    font: normal normal bold 44px/60px exo soft bold;
  }

  h3.msg-click-button-portrait {
    font: 33px/40px "exo soft medium";
    margin-top: 40px;
  }
}
