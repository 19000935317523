div.area-header-shopping-cart {
	float:left; 
	width:100%; 
	background-color:#42abad; 
	background-image:url("../../../assets/images/icone-2.png"); 
	background-repeat:no-repeat; 
	background-position:114% -190px;
	animation-duration:1s;
	animation-name:showTopToBottomComponent1;
}
div.area-header-shopping-cart div.top-header {
	float:left; 
	width:100%;}
div.area-header-shopping-cart div.top-header a {
	float:left; 
	color:#ffffff; 
	text-decoration:none; 
	font-size:40px; 
	margin-top:30px; 
	display:flex; 
	align-items:center; 
	align-content:center;
}
div.area-header-shopping-cart div.top-header a svg {
	float:left; 
	margin-right:0px; 
	margin-right:-20px; 
	margin-top:5px;
}
div.area-header-shopping-cart div.bottom-header {float:left; width:100%; margin-bottom:30px;  display:flex; justify-content:center; align-items:flex-end; align-content:flex-end;}
div.area-header-shopping-cart div.bottom-header img {margin-right:20px;}
div.area-header-shopping-cart div.bottom-header h1 {font-size:80px; color:#dce169; font-weight:normal;}

@media only screen and (max-height: 1500px) {
	div.area-header-shopping-cart div.top-header a {
		margin-top: 0px;
	}

	div.area-header-shopping-cart div.bottom-header {
		margin-bottom: 0px;
	}
}