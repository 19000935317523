@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.area-page-error {width:100%; height:88vh; background-color:; padding:40px; display:flex; justify-content:center; align-items:center; flex-wrap:wrap;}
div.area-page-error div.container {width:455px !important; background-color:;}
div.area-page-error div.container span {float:left; width:100%; color:#17202a !important; font-size:60px; line-height:60px; font-family:"Archivo Black";}
div.area-page-error div.container h1 {float:left; width:100%; text-align:justify; color:#17202a !important; margin-left:0px;}
div.area-page-error div.container p {float:left; width:100%; color:#17202a !important; text-align:justify;}
body {background-color:#fdfdfd !important;}
ul {padding:0px !important;}

::-webkit-scrollbar {width:7px;}
::-webkit-scrollbar-track-piece {background-color:#efefef;}
::-webkit-scrollbar-thumb {background-color:#17202a;}


div.area-dashboard {float:left; width:100%; display:flex;}

div.area-crud {
	width:100%;
	height:88vh;
	background-color:;
	padding:40px;
	overflow-y:auto;
}

div.showItemsSelect {float:left; max-height:200px; overflow-y:auto;}

div.area-actions-crud {float:left; padding:0px; width:100%; margin-bottom:25px; margin-left:0px; display:flex; justify-content:space-between; align-items:center; align-content:center;}
div.area-actions-crud div.nav-crud {width:auto; padding:0px;}
div.area-actions-crud div.nav-crud a {width:auto; border-radius:0px; margin-right:5px; transition:0.3s;}
div.area-actions-crud div.nav-crud a:hover {background-color:#000000; transition:0.3s}
div.area-actions-crud form {height:auto; width:auto; display:flex; align-items:flex-start; padding:0px; margin-bottom:0px;}

div.area-actions-crud form select.form-select {width:150px; padding:6px 10px !important; height:auto !important;}
div.area-actions-crud form div.input-search {width:250px !important; margin-left:10px;}
div.area-actions-crud form div.input-search input {width:200px !important;}
div.area-actions-crud form button {padding:5px 10px; background-color:#17202a; border-radius:0px; border:1px solid #17202a; width:auto !important; transition:0.3s}
div.area-actions-crud form button:hover {background-color:#000000; transition:0.3s}


h2.title-endpoint {float:left; width:100%; margin-bottom:20px;} 

div.area-crud table {width:100% !important;}
div.area-crud table thead th {border:none; background-color:#17202a; color:#fdfdfe;}
div.area-crud table button {padding:0px 5px; margin-right:4px; background-color:#17202a; transition:0.3s}
div.area-crud table button:hover {background-color:#000000; transition:0.3s}
div.area-crud table button svg {margin-top:-5px;}
div.area-crud table a {padding:0px 5px; margin-right:4px; background-color:#17202a; transition:0.3s}
div.area-crud table a:hover {background-color:#000000; transition:0.3s}
div.area-crud table a svg {margin-top:-5px;}
div.area-crud table button.button-remove-tag {float:left; font-size:13px; padding:3px 4; display:flex; align-items:center; align-content:center;}
div.area-crud table button.button-remove-tag i {margin-left:5px; cursor:pointer;}

form.form-register span.error-message {
	color:red;
	font-size:13px;
}

form.form-register button[type="submit"] {padding:7px 40px; margin-top:10px;}

div.area-page-dashboard {width:100%; height:88vh; background-color:; padding:40px; overflow-y:auto; display: flex; flex-direction: column;}
div.area-page-dashboard div.box-number {background-color:#ffffff; margin:10px; border-radius:5px; border:1px solid #e8e8e8;}
div.area-page-dashboard div.box-number:nth-child(1) {border-bottom:3px solid #ef476f;}
div.area-page-dashboard div.box-number:nth-child(2) {border-bottom:3px solid #ffd166;}
div.area-page-dashboard div.box-number:nth-child(3) {border-bottom:3px solid #06d6a0;}
div.area-page-dashboard div.box-number:nth-child(4) {border-bottom:3px solid #118ab2;}
div.area-page-dashboard div.box-number span {float:left; width:100%; text-align:center; font-size:40px;}
div.area-page-dashboard div.box-number label {float:left; width:100%; text-align:center;}
div.area-page-dashboard div.box-list {background-color:#ffffff; border-radius:5px; border:1px solid #e8e8e8; margin:10px; padding:10px 15px;}
div.area-page-dashboard div.box-list ul {padding:0px; margin:0px; width:100%; height:100%; list-style:none;}


div.area-profile {width:100%; height:88vh; background-color:; padding:40px; overflow-y:auto;}

div.area-main {width:100%;}
header {
	height:9vh;
	background-color:#e8e8e8;
	padding:3px 0px;
}

header > nav {
	width:100;
	height:100%;
	padding:0px 40px;
	display:flex;
	justify-content:flex-end;
	align-items:center;
	align-content:center;
}

header a.btn {margin-right:5px; padding:3px 15px;}

label.welcome {margin-right:30px; margin-top:7px; color:#999999;}

div.dropdown-nav button {padding:3px 15px;}
div.head-div {
	padding:0px 15px !important;
	display:flex; align-items:center;
	align-content:center;
	height:9vh;
}

div.head-div a {
	color:#fdfdfe;
}

span.icon-suffix {padding:0px !important; margin:0px !important;}
span.icon-suffix svg {margin-left:10px; font-size:30px !important;}

.link-top-sidebar, .link-sidebar {
	transition:0.3s;
}

.link-top-sidebar:hover, .link-sidebar:hover {
	-webkit-transform:translateX(5px);
	        transform:translateX(5px);
	transition:0.3s;
}

.link-top-sidebar svg, .link-sidebar svg {margin-right:15px; font-size:25px;}
ul.pagination {padding:0px !important; list-style:none; display:flex;}
ul.pagination li {margin-right:2px !important;}
ul.pagination li button {padding:4px 10px; border-radius:0px; transition:0.3s;}
ul.pagination li button:hover:not(.active) {-webkit-transform:translateY(-3px);transform:translateY(-3px); transition:0.3s;}
ul.pagination li button.active {cursor:default; border:1px solid #999999;}
ul.pagination li button.active:focus {outline:none;}
ul.pagination li:first-child {margin-right:8px !important;}
ul.pagination li:last-child {margin-left:8px !important;}

div.endpoints-premissions-input ul {list-style:none; margin-top:20px;}
div.endpoints-premissions-input ul li button {padding:3px 7px; font-size:12px;}
div.area-toten-panel-landscape {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  margin: 0 auto;
}

div.wrapper-landscape {
  width: 100%;
  height: 100%;
  background: url(/static/media/background-1.6214e770.png) no-repeat;
  background-size: cover;
}

@-webkit-keyframes showLeftToRightComponent1 {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent1 {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent2 {
  from {
    -webkit-transform: translateX(-700px);
            transform: translateX(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent2 {
  from {
    -webkit-transform: translateX(-700px);
            transform: translateX(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent3 {
  from {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent3 {
  from {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent4 {
  from {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent4 {
  from {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent5 {
  from {
    -webkit-transform: translateX(-1600px);
            transform: translateX(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent5 {
  from {
    -webkit-transform: translateX(-1600px);
            transform: translateX(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent6 {
  from {
    -webkit-transform: translateX(-1900px);
            transform: translateX(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent6 {
  from {
    -webkit-transform: translateX(-1900px);
            transform: translateX(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent7 {
  from {
    -webkit-transform: translateX(-2200px);
            transform: translateX(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent7 {
  from {
    -webkit-transform: translateX(-2200px);
            transform: translateX(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent8 {
  from {
    -webkit-transform: translateX(-2500px);
            transform: translateX(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent8 {
  from {
    -webkit-transform: translateX(-2500px);
            transform: translateX(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent1 {
  from {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent1 {
  from {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent2 {
  from {
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent2 {
  from {
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent3 {
  from {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent3 {
  from {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent4 {
  from {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent4 {
  from {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent5 {
  from {
    -webkit-transform: translateX(1600px);
            transform: translateX(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent5 {
  from {
    -webkit-transform: translateX(1600px);
            transform: translateX(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent6 {
  from {
    -webkit-transform: translateX(1900px);
            transform: translateX(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent6 {
  from {
    -webkit-transform: translateX(1900px);
            transform: translateX(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent7 {
  from {
    -webkit-transform: translateX(2200px);
            transform: translateX(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent7 {
  from {
    -webkit-transform: translateX(2200px);
            transform: translateX(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent8 {
  from {
    -webkit-transform: translateX(2500px);
            transform: translateX(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent8 {
  from {
    -webkit-transform: translateX(2500px);
            transform: translateX(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent1 {
  from {
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent1 {
  from {
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent2 {
  from {
    -webkit-transform: translateY(-700px);
            transform: translateY(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent2 {
  from {
    -webkit-transform: translateY(-700px);
            transform: translateY(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent3 {
  from {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent3 {
  from {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent4 {
  from {
    -webkit-transform: translateY(-1300px);
            transform: translateY(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent4 {
  from {
    -webkit-transform: translateY(-1300px);
            transform: translateY(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent5 {
  from {
    -webkit-transform: translateY(-1600px);
            transform: translateY(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent5 {
  from {
    -webkit-transform: translateY(-1600px);
            transform: translateY(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent6 {
  from {
    -webkit-transform: translateY(-1900px);
            transform: translateY(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent6 {
  from {
    -webkit-transform: translateY(-1900px);
            transform: translateY(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent7 {
  from {
    -webkit-transform: translateY(-2200px);
            transform: translateY(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent7 {
  from {
    -webkit-transform: translateY(-2200px);
            transform: translateY(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent8 {
  from {
    -webkit-transform: translateY(-2500px);
            transform: translateY(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent8 {
  from {
    -webkit-transform: translateY(-2500px);
            transform: translateY(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent1 {
  from {
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent1 {
  from {
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent2 {
  from {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent2 {
  from {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent3 {
  from {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent3 {
  from {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent4 {
  from {
    -webkit-transform: translateY(1300px);
            transform: translateY(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent4 {
  from {
    -webkit-transform: translateY(1300px);
            transform: translateY(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent5 {
  from {
    -webkit-transform: translateY(1600px);
            transform: translateY(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent5 {
  from {
    -webkit-transform: translateY(1600px);
            transform: translateY(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent6 {
  from {
    -webkit-transform: translateY(1900px);
            transform: translateY(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent6 {
  from {
    -webkit-transform: translateY(1900px);
            transform: translateY(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent7 {
  from {
    -webkit-transform: translateY(2200px);
            transform: translateY(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent7 {
  from {
    -webkit-transform: translateY(2200px);
            transform: translateY(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent8 {
  from {
    -webkit-transform: translateY(2500px);
            transform: translateY(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent8 {
  from {
    -webkit-transform: translateY(2500px);
            transform: translateY(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@font-face {
  font-family: exo soft regular;
  src: url(/static/media/AdobeClean-Regular.5572ba78.otf);
}

@font-face {
  font-family: exo soft medium;
  src: url(/static/media/AdobeClean-Medium.8d951852.otf);
}

@font-face {
  font-family: exo soft semi bold;
  src: url(/static/media/AdobeClean-BoldSemiCn.09cf4658.otf);
}

@font-face {
  font-family: exo soft bold;
  src: url(/static/media/AdobeClean-Bold.ebfd1bee.otf);
}

@font-face {
  font-family: exo soft italic bold;
  src: url(/static/media/AdobeClean-ExtraBoldIt.c61fe7bf.otf);
}

div.page-home-landscape {
  max-width: 1106px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

div.page-home-landscape img.logo-landscape {
  margin: 10px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
  width: 300px;
  height: 300px;
}

a.btn-init-landscape {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent4;
          animation-name: showLeftToRightComponent4;
  margin-bottom: 200px;
}

@media only screen and (max-height: 750px) {
  div.page-home-landscape img.logo-landscape {
    margin-top: -50px;
    width: 250px;
    height: 250px;
  }
}
footer {
  width: 100%;
  position: relative;
  bottom: 151px;
  background: url(/static/media/icone-3.a2c559da.png) no-repeat;
  background-position: center -70px;
  background-color: #1f202d;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 30px 80px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showBottomToTopComponent6;
          animation-name: showBottomToTopComponent6;
}

footer div.social-networks {
  display: flex;
}
footer div.social-networks span {
  background-color: #42abad;
  margin-right: 15px;
  color: #ffffff;
  font-size: 25px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer div.social-networks label {
  color: #fdfdfe;
  font-size: 30px;
  margin-left: 10px;
  font-family: "arial";
}

footer div.opening-time {
  display: flex;
  align-items: center;
  align-content: center;
}
footer div.opening-time label {
  color: #ffffff;
  font-size: 30px;
  margin-left: 20px;
}

@media only screen and (max-height: 1320px) {
  footer {
    bottom: 112px;
    padding: 10px 20px;
  }
}

@media only screen and (max-height: 600px) {
  footer {
    bottom: 80px;
  }

  footer div.social-networks span {
    width: 42px;
    height: 42px;
  }

  footer div.social-networks label {
    font-size: 25px;
  }

	footer div.opening-time label {
		font-size: 25px;
	}

	footer div.opening-time img {
    width: 60px;
    height: 60px;
	}
}

button.btn-zaitt {
	width:511px;
	height:93px;
	color:#161922;
	text-decoration:none;
	font-weight:bold;
	border-radius:52px;
	background: #DCE169 0% 0% no-repeat padding-box;
	box-shadow: 0px 5px 6px #0000004D;
	border:none;
	font: normal normal bold 32px/38px "exo soft semi bold";
}

@media only screen and (max-height: 1460px) {
	button.btn-zaitt {
		width: 480px;
		height: 70px;
		font: normal normal bold 26px/30px "exo soft semi bold";
	}
}
div.area-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}
div.area-modal div.modal-insert-cpf {
  position: relative;
  padding: 60px;
  width: 55%;
  border: 3px solid #42abad;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
}
div.area-modal div.modal-insert-cpf label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}
div.area-modal div.modal-insert-cpf input.cpf {
  width: 100%;
  border-radius: 5px;
  color: #666666;
  margin: 15px 0px;
  border: 2px solid #666666;
  padding: 20px;
  font-size: 28px;
}
div.area-modal div.modal-insert-cpf button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: -25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.area-modal div.modal-insert-cpf button.btn-close-modal svg {
  margin-top: -5px;
}
div.area-modal div.modal-insert-cpf button.btn-number {
  background-color: transparent;
  width: 100%;
  height: 70px;
  font-size: 40px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 15px 0px;
}
div.area-modal div.modal-insert-cpf button.btn-number:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-cpf button.btn-delete-key {
  background-color: #e2e2e2;
  width: 100%;
  height: 70px;
  font-size: 40px;
  border: none;
  border-radius: 7px;
  margin: 15px 0px;
}
div.area-modal div.modal-insert-cpf button.btn-delete-key svg {
  margin-top: -10px;
}
div.area-btn-confirm-cpf {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media only screen and (max-height: 900px) {
	div.area-modal div.modal-insert-cpf {
		height: 100%;
		width: 100%;
		padding: 0px 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: unset;
	}

	div.area-modal div.modal-insert-cpf label {
		font-size: 28px;
		margin-bottom: 5px;
	}

	div.area-modal div.modal-insert-cpf input.cpf {
		margin: 5px 0px;
		padding: 10px 20px;
		font-size: 22px;
	}

	div.area-modal div.modal-insert-cpf button.btn-number {
		height: 50px;
		font-size: 25px;
		margin: 5px 0px;
	}

	div.area-modal div.modal-insert-cpf button.btn-delete-key {
		height: 50px;
		font-size: 25px;
		margin: 5px 0px;
	}

	div.area-btn-confirm-cpf {
		margin-top: 10px;
	}

	div.area-btn-confirm-cpf div button {
		width: 400px;
		height: 50px;
		font: normal normal bold 22px/24px "exo soft semi bold";
	}

	div.area-modal div.modal-insert-cpf button.btn-close-modal {
		right: 2px;
		top: 2px;
	}
}

div.area-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}
div.area-modal div.modal-insert-ticket {
  position: relative;
  padding: 60px;
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 3px solid #42abad;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
}
div.area-modal div.modal-insert-ticket label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}
div.area-modal div.modal-insert-ticket div.line-characters {
  display: flex;
  justify-content: center;
}
div.area-modal div.modal-insert-ticket input.ticket {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  color: #666666;
  margin: 15px 0px;
  border: 2px solid #666666;
  padding: 7px;
  font-size: 22px;
}
div.area-modal div.modal-insert-ticket button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: -25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.area-modal div.modal-insert-ticket button.btn-close-modal svg {
  margin-top: -5px;
}
div.area-modal div.modal-insert-ticket button.btn-number {
  background-color: #c6e6e6;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 10px 7px;
}
div.area-modal div.modal-insert-ticket button.btn-number:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-ticket button.btn-char {
  background-color: transparent;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 10px 7px;
}
div.area-modal div.modal-insert-ticket button.btn-char:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key {
  background-color: #e2e2e2;
  width: 200px;
  height: 65px;
  font-size: 40px;
  border: none;
  border-radius: 7px;
  margin: 10px 0px;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key:active {
	background-color: #989898;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key svg {
  margin-top: -15px;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key.landscape {
	display: none;
}
div.area-btn-confirm-ticket {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media only screen and (max-height: 900px) {
	div.area-modal div.modal-insert-ticket {
		width: 100%;
		height: 100%;
		border-radius: unset;
		padding: 0px 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: unset;
	}

	div.area-modal div.modal-insert-ticket label {
		font-size: 28px;
		margin-bottom: 5px;
	}

	div.area-modal div.modal-insert-ticket input.ticket {
		margin: 5px 0px;
		border: 2px solid #666666;
		padding: 4px 7px;
		font-size: 18px;
	}

	div.area-modal div.modal-insert-ticket button.btn-close-modal {
		right: 2px;
		top: 2px;
	}

	div.area-modal div.modal-insert-ticket button.btn-number {
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin: 7px 7px;
	}

	div.area-modal div.modal-insert-ticket button.btn-char {
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin: 7px 7px;
	}

	div.area-modal div.modal-insert-ticket button.btn-delete-key.portrait {
		display: none;
	}

	div.area-modal div.modal-insert-ticket button.btn-delete-key.landscape {
		display: block;
		width: 92px;
		height: 40px;
		font-size: 30px;
		margin: 7px 7px;
	}

	div.area-btn-confirm-ticket {
		margin-top: 10px;
	}

	div.area-btn-confirm-ticket div button {
		width: 400px;
		height: 50px;
		font: normal normal bold 22px/24px "exo soft semi bold";
	}
}
div.modal-select-card {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 201;
  background-color: #e6e6e6;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showBottomToTopComponent1;
          animation-name: showBottomToTopComponent1;
	display: none;
}
div.modal-select-card div.area-list-cards {
  width: 100%;
  background-color: #ffffff;
  border-top: 3px solid #42abad;
  padding-top: 30px;
}
div.modal-select-card div.area-list-cards label {
  font-size: 30px;
  margin-bottom: 20px;
}
div.modal-select-card div.area-list-cards ul.list-cards {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
}
div.modal-select-card div.area-list-cards ul.list-cards li {
  transition: 0.3s;
}
div.modal-select-card div.area-list-cards ul.list-cards li:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s;
}

div.modal-select-card div.area-list-cards button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.modal-select-card div.area-list-cards button.btn-close-modal svg {
  margin-top: -5px;
}

@media only screen and (max-height: 900px) {
	div.modal-select-card {
		top: 0;
	}

	div.modal-select-card div.area-list-cards {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: 3px solid #42abad;
	}

	div.modal-select-card div.area-list-cards button.btn-close-modal {
		right: 2px;
		top: 2px;
	}

	div.modal-select-card div.area-list-cards label {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
	}
}
.progress-div {
	position:fixed;
	width:100%;
	height:4px;
	bottom:0px;
	left:0px;
	z-index:200;
    background-color: rgb(233, 233, 233);
    border-radius: .5rem;
}

.bar-time-operation {
	background-color: #42abad;
	height:4px;
	border-top-right-radius: 1rem;
	transition: 1s ease;
	transition-delay: 0.5s;
}
div.area-read-bar-code {
  float: left;
  width: 100%;
  height: 1100px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showBottomToTopComponent1;
          animation-name: showBottomToTopComponent1;
}
div.area-read-bar-code > p.msg-scan {
  width: 80%;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 30px;
}

@media only screen and (max-height: 1100px) {
  div.area-read-bar-code {
		height: 100%;
	}

	div.area-read-bar-code img {
		height: 200px;
	}
}
div.area-cart-landscape {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

div.area-cart-landscape form.form-search-product {
  position: fixed;
  left: 0px;
  top: 50%;
}

div.area-cart-landscape > p.msg-scan {
  width: 100%;
  display: none;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 30px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.area-cart-landscape h2 {
  margin-bottom: 20px;
}
div.area-cart-landscape ul {
  display: none;
  width: 90%;
  height: 214px;
  overflow-y: auto;
  list-style: none;
  padding: 15px 0px !important;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}

div.area-cart-landscape ul::-webkit-scrollbar {
  width: 18px;
}

div.area-cart-landscape ul::-webkit-scrollbar-track-piece {
  background-color: #f6f6f6;
}

div.area-cart-landscape ul::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

div.area-cart-landscape ul li {
  float: left;
  width: 98%;
  padding: 14px;
  padding-top: 20px;
  transition: 0.3s;
  border-top: 1px dotted #666666;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.area-cart-landscape ul li:first-child {
  border-top: none;
}
div.area-cart-landscape ul li:hover {
  background-color: #f6f6f6;
  transition: 0.3s;
}
div.area-cart-landscape ul li img {
  float: left;
  height: 140px !important;
  width: 100%;
  object-fit: contain;
}
div.area-cart-landscape ul li div.area-name-product {
  display: flex;
  height: 100%;
  margin-bottom: 10px;
}
div.area-cart-landscape ul li div.area-name-product label {
  font-weight: bold;
  font-size: 24px;
}
div.area-cart-landscape ul li div.area-price-product {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}
div.area-cart-landscape ul li div.area-price-product span {
  font-weight: bold;
  font-size: 24px;
  margin-top: -5px;
}
div.area-cart-landscape ul li div.area-btn-remove-product {
  display: flex;
  height: 100%;
  align-content: flex-end;
  align-items: flex-end;
}
div.area-cart-landscape ul li div.area-btn-remove-product button {
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #c8c8c8;
  font-size: 22px;
}
div.area-cart-landscape ul li div.area-qtd-product {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
div.area-cart-landscape ul li div.area-qtd-product label {
  float: left;
  font-weight: bold;
	margin-right: 10px;
	margin-bottom: 0px;
	font-size: 24px;
}
div.area-cart-landscape ul li div.area-qtd-product input {
  float: left;
  text-align: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
	font-size: 24px;
	width: 40px;
}
div.area-cart-landscape
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod {
	width: 40px;
	height: 40px;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #666666;
  font-weight: bold;
}
div.area-cart-landscape
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod
  span {
  margin-top: -5px;
}

div.area-form-checkout-landscape {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  border-top: 4px solid #42abad;
  margin-top: 10px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showBottomToTopComponent1;
          animation-name: showBottomToTopComponent1;
}
div.area-form-checkout-landscape form {
  float: left;
  width: 95%;
  padding: 10px 0px;
}
div.area-form-checkout-landscape form div.area-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
div.area-form-checkout-landscape form div.area-buttons .button {
  width: 280px;
  height: 50px;
  margin-top: 7px;
  border-radius: 7px;
  border: 2px solid #42abad;
  font-size: 24px;
  background-color: transparent;
}
div.area-form-checkout-landscape form div.area-buttons .button svg {
  margin-left: 10px;
  margin-top: -5px;
}
div.area-form-checkout-landscape form div.area-buttons .button .icon-loading {
  -webkit-animation: gira 1s linear infinite;
          animation: gira 1s linear infinite;
}

div.area-form-checkout-landscape form div.area-price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 25%;
  margin: 10px 0;
}
div.area-form-checkout-landscape form div.area-price label {
  font-size: 30px;
  color: #707070;
}
div.area-form-checkout-landscape form div.area-price div span {
  font-size: 30px;
  font-weight: bold;
}
div.area-form-checkout-landscape form div.area-price div span.without-discount {
  font-size: 24px;
  text-decoration: line-through;
  margin-right: 5px;
  color: #848484;
}
div.area-form-checkout-landscape form div.area-price div button {
  margin-left: 16px;
  background-color: transparent;
  border: none;
  padding: 0px;
}
button.btn-open-webcan-barcode {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}

#form-search-product button {
  opacity: 0;
}

#form-search-product {
  opacity: 0.1;
}

/*div.bar-time-operation { transition: 1s ease; transition-delay: 0.5s;}*/

div.container-modal-loading-payment {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  -webkit-transform: translateX(55500vw);
          transform: translateX(55500vw);
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.2);
}
div.container-modal-loading-payment div.modal-loading-payment {
  background-color: #ffffff;
  border: 3px solid #999999;
  border-radius: 0px;
  padding: 40px 50px;
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  display: none;
}
div.container-modal-loading-payment div.modal-loading-payment label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-top: -15px;
  font-family: "exo soft medium";
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.area-img-qrcode {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.container-modal-loading-payment div.modal-loading-payment span {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
div.container-modal-loading-payment div.modal-loading-payment span svg {
  -webkit-animation: gira 1s linear infinite;
          animation: gira 1s linear infinite;
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment
  button {
  padding: 10px 20px;
  font-size: 25px;
  margin: 0px 10px;
}

@-webkit-keyframes gira {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes gira {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

div.container-modal-loading-payment div.area-read-card {
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 40px;
}
div.container-modal-loading-payment div.area-read-card label {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}

div.container-modal-loading-payment div.area-transference-pix {
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 10px 40px;
}
div.container-modal-loading-payment div.area-transference-pix label {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (max-height: 900px) {
  div.container-modal-loading-payment {
    z-index: 400;
  }
  div.container-modal-loading-payment div.modal-loading-payment {
    height: 100%;
    width: 100%;
    border: 3px solid #42abad;
  }
  div.container-modal-loading-payment div.modal-loading-payment label {
    font-size: 28px;
  }
  div.container-modal-loading-payment div.area-read-card label {
    font-size: 22px;
  }

  div.container-modal-loading-payment div.area-transference-pix label {
    font-size: 22px;
  }
}

div.page-self-checkout-landscape {
	position:relative;
	width:100%;
	height:100vh;
}

div.page-self-checkout-landscape div.top-header {
	float:left; 
	width:100%;}
div.page-self-checkout-landscape div.top-header a {
	float:left; 
	text-decoration:none; 
	font-size:15px; 
	margin-top:5px; 
	color: rgb(13, 13, 13);
	display:flex; 
	align-items:center; 
	align-content:center;
}
div.page-self-checkout-landscape div.top-header a svg {
	float:left; 
	margin-right:0px; 
	margin-right:-10px; 
	margin-top:2px;
}
div.page-finish-payment-landscape {
  max-width: 1106px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.page-finish-payment-landscape img.logo {
  width: 150px;
  height: 150px;
  margin: -15px;
}

div.page-finish-payment-landscape p.msg-purchase-successfully {
  float: left;
  width: 80%;
  max-width: 400px;
  font-size: 36px;
  text-align: center;
  margin-top: -50px;
  font-family: "exo soft bold";
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent4;
          animation-name: showLeftToRightComponent4;
}

div.page-finish-payment-landscape div.image-info-wrapper {
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.page-finish-payment-landscape label.msg-obs {
  float: left;
  font-size: 24px;
  margin: 0 20px;
  font-family: "exo soft regular";
  text-align: center;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent5;
          animation-name: showLeftToRightComponent5;
}
div.area-toten-panel-portrait {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  margin: 0 auto;
}

div.wrapper-portrait {
  width: 100%;
  height: 100%;
  background: url(/static/media/background-1.6214e770.png) no-repeat;
  background-size: cover;
}

@-webkit-keyframes showLeftToRightComponent1 {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent1 {
  from {
    -webkit-transform: translateX(-400px);
            transform: translateX(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent2 {
  from {
    -webkit-transform: translateX(-700px);
            transform: translateX(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent2 {
  from {
    -webkit-transform: translateX(-700px);
            transform: translateX(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent3 {
  from {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent3 {
  from {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent4 {
  from {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent4 {
  from {
    -webkit-transform: translateX(-1300px);
            transform: translateX(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent5 {
  from {
    -webkit-transform: translateX(-1600px);
            transform: translateX(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent5 {
  from {
    -webkit-transform: translateX(-1600px);
            transform: translateX(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent6 {
  from {
    -webkit-transform: translateX(-1900px);
            transform: translateX(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent6 {
  from {
    -webkit-transform: translateX(-1900px);
            transform: translateX(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent7 {
  from {
    -webkit-transform: translateX(-2200px);
            transform: translateX(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent7 {
  from {
    -webkit-transform: translateX(-2200px);
            transform: translateX(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showLeftToRightComponent8 {
  from {
    -webkit-transform: translateX(-2500px);
            transform: translateX(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent8 {
  from {
    -webkit-transform: translateX(-2500px);
            transform: translateX(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent1 {
  from {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent1 {
  from {
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent2 {
  from {
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent2 {
  from {
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent3 {
  from {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent3 {
  from {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent4 {
  from {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent4 {
  from {
    -webkit-transform: translateX(1300px);
            transform: translateX(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent5 {
  from {
    -webkit-transform: translateX(1600px);
            transform: translateX(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent5 {
  from {
    -webkit-transform: translateX(1600px);
            transform: translateX(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent6 {
  from {
    -webkit-transform: translateX(1900px);
            transform: translateX(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent6 {
  from {
    -webkit-transform: translateX(1900px);
            transform: translateX(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent7 {
  from {
    -webkit-transform: translateX(2200px);
            transform: translateX(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent7 {
  from {
    -webkit-transform: translateX(2200px);
            transform: translateX(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showRightToLeftComponent8 {
  from {
    -webkit-transform: translateX(2500px);
            transform: translateX(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent8 {
  from {
    -webkit-transform: translateX(2500px);
            transform: translateX(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent1 {
  from {
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent1 {
  from {
    -webkit-transform: translateY(-400px);
            transform: translateY(-400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent2 {
  from {
    -webkit-transform: translateY(-700px);
            transform: translateY(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent2 {
  from {
    -webkit-transform: translateY(-700px);
            transform: translateY(-700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent3 {
  from {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent3 {
  from {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent4 {
  from {
    -webkit-transform: translateY(-1300px);
            transform: translateY(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent4 {
  from {
    -webkit-transform: translateY(-1300px);
            transform: translateY(-1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent5 {
  from {
    -webkit-transform: translateY(-1600px);
            transform: translateY(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent5 {
  from {
    -webkit-transform: translateY(-1600px);
            transform: translateY(-1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent6 {
  from {
    -webkit-transform: translateY(-1900px);
            transform: translateY(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent6 {
  from {
    -webkit-transform: translateY(-1900px);
            transform: translateY(-1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent7 {
  from {
    -webkit-transform: translateY(-2200px);
            transform: translateY(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent7 {
  from {
    -webkit-transform: translateY(-2200px);
            transform: translateY(-2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showTopToBottomComponent8 {
  from {
    -webkit-transform: translateY(-2500px);
            transform: translateY(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent8 {
  from {
    -webkit-transform: translateY(-2500px);
            transform: translateY(-2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent1 {
  from {
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent1 {
  from {
    -webkit-transform: translateY(400px);
            transform: translateY(400px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent2 {
  from {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent2 {
  from {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent3 {
  from {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent3 {
  from {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent4 {
  from {
    -webkit-transform: translateY(1300px);
            transform: translateY(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent4 {
  from {
    -webkit-transform: translateY(1300px);
            transform: translateY(1300px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent5 {
  from {
    -webkit-transform: translateY(1600px);
            transform: translateY(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent5 {
  from {
    -webkit-transform: translateY(1600px);
            transform: translateY(1600px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent6 {
  from {
    -webkit-transform: translateY(1900px);
            transform: translateY(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent6 {
  from {
    -webkit-transform: translateY(1900px);
            transform: translateY(1900px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent7 {
  from {
    -webkit-transform: translateY(2200px);
            transform: translateY(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent7 {
  from {
    -webkit-transform: translateY(2200px);
            transform: translateY(2200px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes showBottomToTopComponent8 {
  from {
    -webkit-transform: translateY(2500px);
            transform: translateY(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent8 {
  from {
    -webkit-transform: translateY(2500px);
            transform: translateY(2500px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@font-face {
  font-family: exo soft regular;
  src: url(/static/media/AdobeClean-Regular.5572ba78.otf);
}

@font-face {
  font-family: exo soft medium;
  src: url(/static/media/AdobeClean-Medium.8d951852.otf);
}

@font-face {
  font-family: exo soft semi bold;
  src: url(/static/media/AdobeClean-BoldSemiCn.09cf4658.otf);
}

@font-face {
  font-family: exo soft bold;
  src: url(/static/media/AdobeClean-Bold.ebfd1bee.otf);
}

@font-face {
  font-family: exo soft italic bold;
  src: url(/static/media/AdobeClean-ExtraBoldIt.c61fe7bf.otf);
}

div.page-home-portrait {
  max-width: 1106px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
}

div.page-home-portrait img.logo-portrait {
  margin-top: 130px;
  margin-bottom: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}

h1.msg-welcome-portrait {
  width: 100%;
  text-align: center;
  color: #1f202d;
  font: 900 88px/106px "Exo Soft italic bold";
  margin-bottom: 30px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent2;
          animation-name: showLeftToRightComponent2;
}

h2.msg-first-smart-store-portrait {
  width: 729px;
  color: #ffffff;
  font: normal normal bold 56px/70px exo soft bold;
  text-align: center;
  margin-bottom: 60px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent3;
          animation-name: showLeftToRightComponent3;
}

a.btn-init-portrait {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent4;
          animation-name: showLeftToRightComponent4;
}

h3.msg-click-button-portrait {
  width: 729px;
  text-align: center;
  font: 44px/52px "exo soft medium";
  letter-spacing: 0px;
  color: #1f202d;
  margin-top: 90px;
  margin-bottom: 130px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent5;
          animation-name: showLeftToRightComponent5;
}

@media only screen and (max-height: 1500px) {
  div.page-home-portrait img.logo-portrait {
    margin: 0px;
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-height: 1320px) {
  div.page-home-portrait {
    max-width: 800px;
  }

  h1.msg-welcome-portrait {
    font: 900 66px/94px "Exo Soft italic bold";
  }

  h2.msg-first-smart-store-portrait {
    font: normal normal bold 44px/60px exo soft bold;
  }

  h3.msg-click-button-portrait {
    font: 33px/40px "exo soft medium";
    margin-top: 40px;
  }
}

div.area-purchased-products {
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

div.area-purchased-products form.form-search-product {
  position: fixed;
  left: 0px;
  top: 50%;
}

div.area-purchased-products > p.msg-scan {
  width: 100%;
  display: none;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 30px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.area-purchased-products h2 {
  margin-bottom: 20px;
}
div.area-purchased-products ul {
  display: none;
  width: 90%;
  height: 520px;
  overflow-y: auto;
  list-style: none;
  padding: 15px 0px !important;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}

div.area-purchased-products ul::-webkit-scrollbar {
  width: 18px;
}

div.area-purchased-products ul::-webkit-scrollbar-track-piece {
  background-color: #f6f6f6;
}

div.area-purchased-products ul::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

div.area-purchased-products ul li {
  float: left;
  width: 98%;
  padding: 14px;
  padding-top: 20px;
  transition: 0.3s;
  border-top: 1px dotted #666666;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.area-purchased-products ul li:first-child {
  border-top: none;
}
div.area-purchased-products ul li:hover {
  background-color: #f6f6f6;
  transition: 0.3s;
}
div.area-purchased-products ul li img {
  float: left;
  height: 180px !important;
  width: 100%;
  object-fit: contain;
}
div.area-purchased-products ul li div.area-name-product {
  display: flex;
  height: 100%;
  margin-bottom: 10px;
}
div.area-purchased-products ul li div.area-name-product label {
  font-weight: bold;
  font-size: 28px;
}
div.area-purchased-products ul li div.area-price-product {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}
div.area-purchased-products ul li div.area-price-product span {
  font-weight: bold;
  font-size: 40px;
  margin-top: -5px;
}
div.area-purchased-products ul li div.area-btn-remove-product {
  display: flex;
  height: 100%;
  align-content: flex-end;
  align-items: flex-end;
}
div.area-purchased-products ul li div.area-btn-remove-product button {
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #c8c8c8;
  font-size: 22px;
}
div.area-purchased-products ul li div.area-qtd-product {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
}
div.area-purchased-products ul li div.area-qtd-product label {
  float: left;
  font-weight: bold;
  margin-right: 20px;
  font-size: 28px;
}
div.area-purchased-products ul li div.area-qtd-product input {
  float: left;
  width: 80px;
  text-align: center;
  font-weight: bold;
  border: none;
  font-size: 42px;
  background-color: transparent;
}
div.area-purchased-products
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod {
  width: 65px;
  height: 65px;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #666666;
  font-weight: bold;
}
div.area-purchased-products
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod
  span {
  margin-top: -5px;
}

div.area-form-checkout-portrait {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 4px solid #42abad;
  margin-top: 20px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showBottomToTopComponent1;
          animation-name: showBottomToTopComponent1;
}
div.area-form-checkout-portrait form {
  float: left;
  width: 80%;
  padding: 20px 0px;
}
div.area-form-checkout-portrait form div.area-buttons {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.area-form-checkout-portrait form div.area-buttons button {
  width: 360px;
  margin: 20px;
  border-radius: 7px;
  border: 2px solid #42abad;
  padding: 19px 0px;
  font-size: 24px;
  background-color: transparent;
}
div.area-form-checkout-portrait form div.area-buttons button svg {
  margin-left: 10px;
  margin-top: -8px;
}
div.area-form-checkout-portrait form div.area-buttons button .icon-loading {
  -webkit-animation: gira 1s linear infinite;
          animation: gira 1s linear infinite;
}
div.area-form-checkout-portrait form div.area-discount {
  width: 100%;
  margin-top: 50px;
}
div.area-form-checkout-portrait form div.area-discount div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
div.area-form-checkout-portrait form div.area-discount div label {
  font-size: 30px;
  color: #707070;
}
div.area-form-checkout-portrait form div.area-discount div span {
  font-size: 30px;
  font-weight: bold;
}
div.area-form-checkout-portrait form div.area-discount div button {
  margin-left: 16px;
  background-color: transparent;
  border: none;
  padding: 0px;
}
div.area-form-checkout-portrait form div.area-price {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
div.area-form-checkout-portrait form div.area-price label {
  font-size: 50px;
  color: #707070;
}
div.area-form-checkout-portrait form div.area-price span {
  font-size: 50px;
  font-weight: bold;
}
div.area-form-checkout-portrait form div.area-button-submit {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

button.btn-open-webcan-barcode {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}

/*div.area-purchased-products div.modal-loading-payment {position:fixed; display:none; width:300px; height:200px; background-color:#ffffff; border:7px solid #03a6b0; border-radius:3px; z-index:100;
	-webkit-box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.33); 
	box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.33);
	justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
	left: calc( 50% - 150px );
}
div.area-purchased-products div.modal-loading-payment button {
    background-color: transparent;
    border: 2px gray solid;
    border-radius: 4px;
    text-transform: uppercase;
	margin-bottom: 15px;
}*/
div.area-purchased-products div.container-modal-loading-payment {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  -webkit-transform: translateX(55500vw);
          transform: translateX(55500vw);
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.2);
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment {
  background-color: #ffffff;
  border: 3px solid #999999;
  border-radius: 0px;
  padding: 40px 50px;
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  display: none;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-top: -15px;
  font-family: "exo soft medium";
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.area-img-qrcode {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  span {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  span
  svg {
  -webkit-animation: gira 1s linear infinite;
          animation: gira 1s linear infinite;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment
  button {
  padding: 10px 20px;
  font-size: 25px;
  margin: 0px 10px;
}

@media only screen and (max-height: 1600px) {
  div.area-form-checkout-portrait {
    margin-top: 0px;
  }

  div.area-purchased-products > p.msg-scan {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  div.area-form-checkout-portrait form div.area-buttons {
    margin-top: 0px;
  }

  div.area-form-checkout-portrait form div.area-discount {
    margin-top: 0px;
  }

  div.area-purchased-products ul li div.area-name-product label {
    font-size: 20px;
  }

  div.area-purchased-products {
    margin-bottom: 0px;
  }
}

@media only screen and (max-height: 1366px) {
  div.area-purchased-products
    ul
    li
    div.area-qtd-product
    button.btn-alter-qtd-prod {
    width: 45px;
    height: 45px;
  }

  div.area-purchased-products ul li div.area-qtd-product label {
    margin-right: 10px;
    font-size: 25px;
  }

  div.area-purchased-products ul li div.area-qtd-product input {
    font-size: 35px;
    width: 40px;
  }

  div.area-purchased-products ul {
    height: 445px;
  }

  div.area-purchased-products ul li div.area-price-product span {
    font-size: 30px;
  }
}

@-webkit-keyframes gira {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes gira {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#form-search-product button {
  opacity: 0;
}

#form-search-product {
  opacity: 0.1;
}

/*div.bar-time-operation { transition: 1s ease; transition-delay: 0.5s;}*/

div.area-header-shopping-cart {
	float:left; 
	width:100%; 
	background-color:#42abad; 
	background-image:url(/static/media/icone-2.4c1a8157.png); 
	background-repeat:no-repeat; 
	background-position:114% -190px;
	-webkit-animation-duration:1s;
	        animation-duration:1s;
	-webkit-animation-name:showTopToBottomComponent1;
	        animation-name:showTopToBottomComponent1;
}
div.area-header-shopping-cart div.top-header {
	float:left; 
	width:100%;}
div.area-header-shopping-cart div.top-header a {
	float:left; 
	color:#ffffff; 
	text-decoration:none; 
	font-size:40px; 
	margin-top:30px; 
	display:flex; 
	align-items:center; 
	align-content:center;
}
div.area-header-shopping-cart div.top-header a svg {
	float:left; 
	margin-right:0px; 
	margin-right:-20px; 
	margin-top:5px;
}
div.area-header-shopping-cart div.bottom-header {float:left; width:100%; margin-bottom:30px;  display:flex; justify-content:center; align-items:flex-end; align-content:flex-end;}
div.area-header-shopping-cart div.bottom-header img {margin-right:20px;}
div.area-header-shopping-cart div.bottom-header h1 {font-size:80px; color:#dce169; font-weight:normal;}

@media only screen and (max-height: 1500px) {
	div.area-header-shopping-cart div.top-header a {
		margin-top: 0px;
	}

	div.area-header-shopping-cart div.bottom-header {
		margin-bottom: 0px;
	}
}
div.page-self-checkout {
  position: relative;
  width: 100%;
  height: 100vh;
}

div.page-finish-payment-portrait {
  position: relative;
  max-width: 1106px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent1;
          animation-name: showLeftToRightComponent1;
}
div.page-finish-payment-portrait h1.title-finish {
  float: left;
  width: 100%;
  font-size: 130px;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent2;
          animation-name: showLeftToRightComponent2;
}
div.page-finish-payment-portrait img.image-info-voucher {
  float: left;
  width: 80%;
  margin-bottom: 100px;
  margin-top: 40px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent3;
          animation-name: showLeftToRightComponent3;
}
div.page-finish-payment-portrait p.msg-purchase-successfully {
  float: left;
  width: 100%;
  font-size: 48px;
  text-align: center;
  margin-bottom: 0px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent4;
          animation-name: showLeftToRightComponent4;
}
div.page-finish-payment-portrait label.msg-obs {
  float: left;
  width: 100%;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  font-family: "exo soft bold";
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent5;
          animation-name: showLeftToRightComponent5;
}
div.page-finish-payment-portrait h1.msg-thanks {
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent6;
          animation-name: showLeftToRightComponent6;
}
div.page-finish-payment-portrait h1.msg-thanks span {
  color: #ffffff;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: showLeftToRightComponent7;
          animation-name: showLeftToRightComponent7;
}

@media only screen and (max-height: 1500px) {
  div.page-finish-payment-portrait img.logo-portrait {
    width: 500px;
    height: 500px;
  }

  div.page-finish-payment-portrait h1.title-finish {
    margin-top: -50px;
    font-size: 105px;
  }

  div.page-finish-payment-portrait img.image-info-voucher {
    width: 70%;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

div.login-auth {
	width:100%;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
}

div.login-auth div.container {
	border:1px solid #e8e8e8;
	padding:20px;
}

div.login-auth div.container a {
	border:1px solid #e8e8e8;
}

div.login-auth span.error-message {
	color:red;
	font-size:13px;
}
div.login-auth {
	width:100%;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items:center;
}

div.login-auth div.container {
	border:1px solid #e8e8e8;
	padding:20px;
}

div.login-auth div.container a {
	border:1px solid #e8e8e8;
}

div.login-auth span.error-message {
	color:red;
	font-size:13px;
}
