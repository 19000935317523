div.area-purchased-products {
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

div.area-purchased-products form.form-search-product {
  position: fixed;
  left: 0px;
  top: 50%;
}

div.area-purchased-products > p.msg-scan {
  width: 100%;
  display: none;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 30px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.area-purchased-products h2 {
  margin-bottom: 20px;
}
div.area-purchased-products ul {
  display: none;
  width: 90%;
  height: 520px;
  overflow-y: auto;
  list-style: none;
  padding: 15px 0px !important;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}

div.area-purchased-products ul::-webkit-scrollbar {
  width: 18px;
}

div.area-purchased-products ul::-webkit-scrollbar-track-piece {
  background-color: #f6f6f6;
}

div.area-purchased-products ul::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

div.area-purchased-products ul li {
  float: left;
  width: 98%;
  padding: 14px;
  padding-top: 20px;
  transition: 0.3s;
  border-top: 1px dotted #666666;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.area-purchased-products ul li:first-child {
  border-top: none;
}
div.area-purchased-products ul li:hover {
  background-color: #f6f6f6;
  transition: 0.3s;
}
div.area-purchased-products ul li img {
  float: left;
  height: 180px !important;
  width: 100%;
  object-fit: contain;
}
div.area-purchased-products ul li div.area-name-product {
  display: flex;
  height: 100%;
  margin-bottom: 10px;
}
div.area-purchased-products ul li div.area-name-product label {
  font-weight: bold;
  font-size: 28px;
}
div.area-purchased-products ul li div.area-price-product {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}
div.area-purchased-products ul li div.area-price-product span {
  font-weight: bold;
  font-size: 40px;
  margin-top: -5px;
}
div.area-purchased-products ul li div.area-btn-remove-product {
  display: flex;
  height: 100%;
  align-content: flex-end;
  align-items: flex-end;
}
div.area-purchased-products ul li div.area-btn-remove-product button {
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #c8c8c8;
  font-size: 22px;
}
div.area-purchased-products ul li div.area-qtd-product {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
}
div.area-purchased-products ul li div.area-qtd-product label {
  float: left;
  font-weight: bold;
  margin-right: 20px;
  font-size: 28px;
}
div.area-purchased-products ul li div.area-qtd-product input {
  float: left;
  width: 80px;
  text-align: center;
  font-weight: bold;
  border: none;
  font-size: 42px;
  background-color: transparent;
}
div.area-purchased-products
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod {
  width: 65px;
  height: 65px;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #666666;
  font-weight: bold;
}
div.area-purchased-products
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod
  span {
  margin-top: -5px;
}

div.area-form-checkout-portrait {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 4px solid #42abad;
  margin-top: 20px;
  animation-duration: 1s;
  animation-name: showBottomToTopComponent1;
}
div.area-form-checkout-portrait form {
  float: left;
  width: 80%;
  padding: 20px 0px;
}
div.area-form-checkout-portrait form div.area-buttons {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.area-form-checkout-portrait form div.area-buttons button {
  width: 360px;
  margin: 20px;
  border-radius: 7px;
  border: 2px solid #42abad;
  padding: 19px 0px;
  font-size: 24px;
  background-color: transparent;
}
div.area-form-checkout-portrait form div.area-buttons button svg {
  margin-left: 10px;
  margin-top: -8px;
}
div.area-form-checkout-portrait form div.area-buttons button .icon-loading {
  animation: gira 1s linear infinite;
}
div.area-form-checkout-portrait form div.area-discount {
  width: 100%;
  margin-top: 50px;
}
div.area-form-checkout-portrait form div.area-discount div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
div.area-form-checkout-portrait form div.area-discount div label {
  font-size: 30px;
  color: #707070;
}
div.area-form-checkout-portrait form div.area-discount div span {
  font-size: 30px;
  font-weight: bold;
}
div.area-form-checkout-portrait form div.area-discount div button {
  margin-left: 16px;
  background-color: transparent;
  border: none;
  padding: 0px;
}
div.area-form-checkout-portrait form div.area-price {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
div.area-form-checkout-portrait form div.area-price label {
  font-size: 50px;
  color: #707070;
}
div.area-form-checkout-portrait form div.area-price span {
  font-size: 50px;
  font-weight: bold;
}
div.area-form-checkout-portrait form div.area-button-submit {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

button.btn-open-webcan-barcode {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}

/*div.area-purchased-products div.modal-loading-payment {position:fixed; display:none; width:300px; height:200px; background-color:#ffffff; border:7px solid #03a6b0; border-radius:3px; z-index:100;
	-webkit-box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.33); 
	box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.33);
	justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
	left: calc( 50% - 150px );
}
div.area-purchased-products div.modal-loading-payment button {
    background-color: transparent;
    border: 2px gray solid;
    border-radius: 4px;
    text-transform: uppercase;
	margin-bottom: 15px;
}*/
div.area-purchased-products div.container-modal-loading-payment {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  transform: translateX(55500vw);
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.2);
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment {
  background-color: #ffffff;
  border: 3px solid #999999;
  border-radius: 0px;
  padding: 40px 50px;
  -webkit-box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  display: none;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-top: -15px;
  font-family: "exo soft medium";
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.area-img-qrcode {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  span {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  span
  svg {
  animation: gira 1s linear infinite;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}
div.area-purchased-products
  div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment
  button {
  padding: 10px 20px;
  font-size: 25px;
  margin: 0px 10px;
}

@media only screen and (max-height: 1600px) {
  div.area-form-checkout-portrait {
    margin-top: 0px;
  }

  div.area-purchased-products > p.msg-scan {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  div.area-form-checkout-portrait form div.area-buttons {
    margin-top: 0px;
  }

  div.area-form-checkout-portrait form div.area-discount {
    margin-top: 0px;
  }

  div.area-purchased-products ul li div.area-name-product label {
    font-size: 20px;
  }

  div.area-purchased-products {
    margin-bottom: 0px;
  }
}

@media only screen and (max-height: 1366px) {
  div.area-purchased-products
    ul
    li
    div.area-qtd-product
    button.btn-alter-qtd-prod {
    width: 45px;
    height: 45px;
  }

  div.area-purchased-products ul li div.area-qtd-product label {
    margin-right: 10px;
    font-size: 25px;
  }

  div.area-purchased-products ul li div.area-qtd-product input {
    font-size: 35px;
    width: 40px;
  }

  div.area-purchased-products ul {
    height: 445px;
  }

  div.area-purchased-products ul li div.area-price-product span {
    font-size: 30px;
  }
}

@keyframes gira {
  to {
    transform: rotate(360deg);
  }
}

#form-search-product button {
  opacity: 0;
}

#form-search-product {
  opacity: 0.1;
}

/*div.bar-time-operation { transition: 1s ease; transition-delay: 0.5s;}*/
