div.modal-select-card {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 201;
  background-color: #e6e6e6;
  animation-duration: 1s;
  animation-name: showBottomToTopComponent1;
	display: none;
}
div.modal-select-card div.area-list-cards {
  width: 100%;
  background-color: #ffffff;
  border-top: 3px solid #42abad;
  padding-top: 30px;
}
div.modal-select-card div.area-list-cards label {
  font-size: 30px;
  margin-bottom: 20px;
}
div.modal-select-card div.area-list-cards ul.list-cards {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
}
div.modal-select-card div.area-list-cards ul.list-cards li {
  transition: 0.3s;
}
div.modal-select-card div.area-list-cards ul.list-cards li:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

div.modal-select-card div.area-list-cards button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: 25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.modal-select-card div.area-list-cards button.btn-close-modal svg {
  margin-top: -5px;
}

@media only screen and (max-height: 900px) {
	div.modal-select-card {
		top: 0;
	}

	div.modal-select-card div.area-list-cards {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: 3px solid #42abad;
	}

	div.modal-select-card div.area-list-cards button.btn-close-modal {
		right: 2px;
		top: 2px;
	}

	div.modal-select-card div.area-list-cards label {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
	}
}