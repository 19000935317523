@font-face {
  font-family: exo soft regular;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Regular.otf");
}

@font-face {
  font-family: exo soft medium;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Medium.otf");
}

@font-face {
  font-family: exo soft semi bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-BoldSemiCn.otf");
}

@font-face {
  font-family: exo soft bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-Bold.otf");
}

@font-face {
  font-family: exo soft italic bold;
  src: url("../../../../assets/fonts/exo-soft-2.0/AdobeClean-ExtraBoldIt.otf");
}

div.page-home-landscape {
  max-width: 1106px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

div.page-home-landscape img.logo-landscape {
  margin: 10px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
  width: 300px;
  height: 300px;
}

a.btn-init-landscape {
  animation-duration: 1s;
  animation-name: showLeftToRightComponent4;
  margin-bottom: 200px;
}

@media only screen and (max-height: 750px) {
  div.page-home-landscape img.logo-landscape {
    margin-top: -50px;
    width: 250px;
    height: 250px;
  }
}