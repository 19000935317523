footer {
  width: 100%;
  position: relative;
  bottom: 151px;
  background: url("../../../assets/images/icone-3.png") no-repeat;
  background-position: center -70px;
  background-color: #1f202d;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 30px 80px;
  animation-duration: 1s;
  animation-name: showBottomToTopComponent6;
}

footer div.social-networks {
  display: flex;
}
footer div.social-networks span {
  background-color: #42abad;
  margin-right: 15px;
  color: #ffffff;
  font-size: 25px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer div.social-networks label {
  color: #fdfdfe;
  font-size: 30px;
  margin-left: 10px;
  font-family: "arial";
}

footer div.opening-time {
  display: flex;
  align-items: center;
  align-content: center;
}
footer div.opening-time label {
  color: #ffffff;
  font-size: 30px;
  margin-left: 20px;
}

@media only screen and (max-height: 1320px) {
  footer {
    bottom: 112px;
    padding: 10px 20px;
  }
}

@media only screen and (max-height: 600px) {
  footer {
    bottom: 80px;
  }

  footer div.social-networks span {
    width: 42px;
    height: 42px;
  }

  footer div.social-networks label {
    font-size: 25px;
  }

	footer div.opening-time label {
		font-size: 25px;
	}

	footer div.opening-time img {
    width: 60px;
    height: 60px;
	}
}
