div.area-read-bar-code {
  float: left;
  width: 100%;
  height: 1100px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  animation-duration: 1s;
  animation-name: showBottomToTopComponent1;
}
div.area-read-bar-code > p.msg-scan {
  width: 80%;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 30px;
}

@media only screen and (max-height: 1100px) {
  div.area-read-bar-code {
		height: 100%;
	}

	div.area-read-bar-code img {
		height: 200px;
	}
}