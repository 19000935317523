div.area-toten-panel-landscape {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  margin: 0 auto;
}

div.wrapper-landscape {
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/background-1.png") no-repeat;
  background-size: cover;
}

@keyframes showLeftToRightComponent1 {
  from {
    transform: translateX(-400px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent2 {
  from {
    transform: translateX(-700px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent3 {
  from {
    transform: translateX(-1000px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent4 {
  from {
    transform: translateX(-1300px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent5 {
  from {
    transform: translateX(-1600px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent6 {
  from {
    transform: translateX(-1900px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent7 {
  from {
    transform: translateX(-2200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showLeftToRightComponent8 {
  from {
    transform: translateX(-2500px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent1 {
  from {
    transform: translateX(400px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent2 {
  from {
    transform: translateX(700px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent3 {
  from {
    transform: translateX(1000px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent4 {
  from {
    transform: translateX(1300px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent5 {
  from {
    transform: translateX(1600px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent6 {
  from {
    transform: translateX(1900px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent7 {
  from {
    transform: translateX(2200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showRightToLeftComponent8 {
  from {
    transform: translateX(2500px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent1 {
  from {
    transform: translateY(-400px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent2 {
  from {
    transform: translateY(-700px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent3 {
  from {
    transform: translateY(-1000px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent4 {
  from {
    transform: translateY(-1300px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent5 {
  from {
    transform: translateY(-1600px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent6 {
  from {
    transform: translateY(-1900px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent7 {
  from {
    transform: translateY(-2200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showTopToBottomComponent8 {
  from {
    transform: translateY(-2500px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent1 {
  from {
    transform: translateY(400px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent2 {
  from {
    transform: translateY(700px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent3 {
  from {
    transform: translateY(1000px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent4 {
  from {
    transform: translateY(1300px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent5 {
  from {
    transform: translateY(1600px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent6 {
  from {
    transform: translateY(1900px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent7 {
  from {
    transform: translateY(2200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes showBottomToTopComponent8 {
  from {
    transform: translateY(2500px);
  }
  to {
    transform: translateX(0px);
  }
}
