body {background-color:#fdfdfd !important;}
ul {padding:0px !important;}

::-webkit-scrollbar {width:7px;}
::-webkit-scrollbar-track-piece {background-color:#efefef;}
::-webkit-scrollbar-thumb {background-color:#17202a;}


div.area-dashboard {float:left; width:100%; display:flex;}

div.area-crud {
	width:100%;
	height:88vh;
	background-color:;
	padding:40px;
	overflow-y:auto;
}

div.showItemsSelect {float:left; max-height:200px; overflow-y:auto;}

div.area-actions-crud {float:left; padding:0px; width:100%; margin-bottom:25px; margin-left:0px; display:flex; justify-content:space-between; align-items:center; align-content:center;}
div.area-actions-crud div.nav-crud {width:auto; padding:0px;}
div.area-actions-crud div.nav-crud a {width:auto; border-radius:0px; margin-right:5px; transition:0.3s;}
div.area-actions-crud div.nav-crud a:hover {background-color:#000000; transition:0.3s}
div.area-actions-crud form {height:auto; width:auto; display:flex; align-items:flex-start; padding:0px; margin-bottom:0px;}

div.area-actions-crud form select.form-select {width:150px; padding:6px 10px !important; height:auto !important;}
div.area-actions-crud form div.input-search {width:250px !important; margin-left:10px;}
div.area-actions-crud form div.input-search input {width:200px !important;}
div.area-actions-crud form button {padding:5px 10px; background-color:#17202a; border-radius:0px; border:1px solid #17202a; width:auto !important; transition:0.3s}
div.area-actions-crud form button:hover {background-color:#000000; transition:0.3s}


h2.title-endpoint {float:left; width:100%; margin-bottom:20px;} 

div.area-crud table {width:100% !important;}
div.area-crud table thead th {border:none; background-color:#17202a; color:#fdfdfe;}
div.area-crud table button {padding:0px 5px; margin-right:4px; background-color:#17202a; transition:0.3s}
div.area-crud table button:hover {background-color:#000000; transition:0.3s}
div.area-crud table button svg {margin-top:-5px;}
div.area-crud table a {padding:0px 5px; margin-right:4px; background-color:#17202a; transition:0.3s}
div.area-crud table a:hover {background-color:#000000; transition:0.3s}
div.area-crud table a svg {margin-top:-5px;}
div.area-crud table button.button-remove-tag {float:left; font-size:13px; padding:3px 4; display:flex; align-items:center; align-content:center;}
div.area-crud table button.button-remove-tag i {margin-left:5px; cursor:pointer;}

form.form-register span.error-message {
	color:red;
	font-size:13px;
}

form.form-register button[type="submit"] {padding:7px 40px; margin-top:10px;}

div.area-page-dashboard {width:100%; height:88vh; background-color:; padding:40px; overflow-y:auto; display: flex; flex-direction: column;}
div.area-page-dashboard div.box-number {background-color:#ffffff; margin:10px; border-radius:5px; border:1px solid #e8e8e8;}
div.area-page-dashboard div.box-number:nth-child(1) {border-bottom:3px solid #ef476f;}
div.area-page-dashboard div.box-number:nth-child(2) {border-bottom:3px solid #ffd166;}
div.area-page-dashboard div.box-number:nth-child(3) {border-bottom:3px solid #06d6a0;}
div.area-page-dashboard div.box-number:nth-child(4) {border-bottom:3px solid #118ab2;}
div.area-page-dashboard div.box-number span {float:left; width:100%; text-align:center; font-size:40px;}
div.area-page-dashboard div.box-number label {float:left; width:100%; text-align:center;}
div.area-page-dashboard div.box-list {background-color:#ffffff; border-radius:5px; border:1px solid #e8e8e8; margin:10px; padding:10px 15px;}
div.area-page-dashboard div.box-list ul {padding:0px; margin:0px; width:100%; height:100%; list-style:none;}


div.area-profile {width:100%; height:88vh; background-color:; padding:40px; overflow-y:auto;}
