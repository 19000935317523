div.area-cart-landscape {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

div.area-cart-landscape form.form-search-product {
  position: fixed;
  left: 0px;
  top: 50%;
}

div.area-cart-landscape > p.msg-scan {
  width: 100%;
  display: none;
  text-align: center;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 30px;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.area-cart-landscape h2 {
  margin-bottom: 20px;
}
div.area-cart-landscape ul {
  display: none;
  width: 90%;
  height: 214px;
  overflow-y: auto;
  list-style: none;
  padding: 15px 0px !important;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}

div.area-cart-landscape ul::-webkit-scrollbar {
  width: 18px;
}

div.area-cart-landscape ul::-webkit-scrollbar-track-piece {
  background-color: #f6f6f6;
}

div.area-cart-landscape ul::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border: 1px solid #999999;
  border-radius: 20px;
}

div.area-cart-landscape ul li {
  float: left;
  width: 98%;
  padding: 14px;
  padding-top: 20px;
  transition: 0.3s;
  border-top: 1px dotted #666666;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.area-cart-landscape ul li:first-child {
  border-top: none;
}
div.area-cart-landscape ul li:hover {
  background-color: #f6f6f6;
  transition: 0.3s;
}
div.area-cart-landscape ul li img {
  float: left;
  height: 140px !important;
  width: 100%;
  object-fit: contain;
}
div.area-cart-landscape ul li div.area-name-product {
  display: flex;
  height: 100%;
  margin-bottom: 10px;
}
div.area-cart-landscape ul li div.area-name-product label {
  font-weight: bold;
  font-size: 24px;
}
div.area-cart-landscape ul li div.area-price-product {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}
div.area-cart-landscape ul li div.area-price-product span {
  font-weight: bold;
  font-size: 24px;
  margin-top: -5px;
}
div.area-cart-landscape ul li div.area-btn-remove-product {
  display: flex;
  height: 100%;
  align-content: flex-end;
  align-items: flex-end;
}
div.area-cart-landscape ul li div.area-btn-remove-product button {
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #c8c8c8;
  font-size: 22px;
}
div.area-cart-landscape ul li div.area-qtd-product {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
div.area-cart-landscape ul li div.area-qtd-product label {
  float: left;
  font-weight: bold;
	margin-right: 10px;
	margin-bottom: 0px;
	font-size: 24px;
}
div.area-cart-landscape ul li div.area-qtd-product input {
  float: left;
  text-align: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
	font-size: 24px;
	width: 40px;
}
div.area-cart-landscape
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod {
	width: 40px;
	height: 40px;
  font-size: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #666666;
  font-weight: bold;
}
div.area-cart-landscape
  ul
  li
  div.area-qtd-product
  button.btn-alter-qtd-prod
  span {
  margin-top: -5px;
}

div.area-form-checkout-landscape {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  border-top: 4px solid #42abad;
  margin-top: 10px;
  animation-duration: 1s;
  animation-name: showBottomToTopComponent1;
}
div.area-form-checkout-landscape form {
  float: left;
  width: 95%;
  padding: 10px 0px;
}
div.area-form-checkout-landscape form div.area-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
div.area-form-checkout-landscape form div.area-buttons .button {
  width: 280px;
  height: 50px;
  margin-top: 7px;
  border-radius: 7px;
  border: 2px solid #42abad;
  font-size: 24px;
  background-color: transparent;
}
div.area-form-checkout-landscape form div.area-buttons .button svg {
  margin-left: 10px;
  margin-top: -5px;
}
div.area-form-checkout-landscape form div.area-buttons .button .icon-loading {
  animation: gira 1s linear infinite;
}

div.area-form-checkout-landscape form div.area-price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 25%;
  margin: 10px 0;
}
div.area-form-checkout-landscape form div.area-price label {
  font-size: 30px;
  color: #707070;
}
div.area-form-checkout-landscape form div.area-price div span {
  font-size: 30px;
  font-weight: bold;
}
div.area-form-checkout-landscape form div.area-price div span.without-discount {
  font-size: 24px;
  text-decoration: line-through;
  margin-right: 5px;
  color: #848484;
}
div.area-form-checkout-landscape form div.area-price div button {
  margin-left: 16px;
  background-color: transparent;
  border: none;
  padding: 0px;
}
button.btn-open-webcan-barcode {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}

#form-search-product button {
  opacity: 0;
}

#form-search-product {
  opacity: 0.1;
}

/*div.bar-time-operation { transition: 1s ease; transition-delay: 0.5s;}*/
