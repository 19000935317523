div.area-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}
div.area-modal div.modal-insert-ticket {
  position: relative;
  padding: 60px;
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 3px solid #42abad;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
}
div.area-modal div.modal-insert-ticket label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}
div.area-modal div.modal-insert-ticket div.line-characters {
  display: flex;
  justify-content: center;
}
div.area-modal div.modal-insert-ticket input.ticket {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  color: #666666;
  margin: 15px 0px;
  border: 2px solid #666666;
  padding: 7px;
  font-size: 22px;
}
div.area-modal div.modal-insert-ticket button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: -25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.area-modal div.modal-insert-ticket button.btn-close-modal svg {
  margin-top: -5px;
}
div.area-modal div.modal-insert-ticket button.btn-number {
  background-color: #c6e6e6;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 10px 7px;
}
div.area-modal div.modal-insert-ticket button.btn-number:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-ticket button.btn-char {
  background-color: transparent;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 10px 7px;
}
div.area-modal div.modal-insert-ticket button.btn-char:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key {
  background-color: #e2e2e2;
  width: 200px;
  height: 65px;
  font-size: 40px;
  border: none;
  border-radius: 7px;
  margin: 10px 0px;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key:active {
	background-color: #989898;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key svg {
  margin-top: -15px;
}
div.area-modal div.modal-insert-ticket button.btn-delete-key.landscape {
	display: none;
}
div.area-btn-confirm-ticket {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media only screen and (max-height: 900px) {
	div.area-modal div.modal-insert-ticket {
		width: 100%;
		height: 100%;
		border-radius: unset;
		padding: 0px 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: unset;
	}

	div.area-modal div.modal-insert-ticket label {
		font-size: 28px;
		margin-bottom: 5px;
	}

	div.area-modal div.modal-insert-ticket input.ticket {
		margin: 5px 0px;
		border: 2px solid #666666;
		padding: 4px 7px;
		font-size: 18px;
	}

	div.area-modal div.modal-insert-ticket button.btn-close-modal {
		right: 2px;
		top: 2px;
	}

	div.area-modal div.modal-insert-ticket button.btn-number {
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin: 7px 7px;
	}

	div.area-modal div.modal-insert-ticket button.btn-char {
		width: 40px;
		height: 40px;
		font-size: 20px;
		margin: 7px 7px;
	}

	div.area-modal div.modal-insert-ticket button.btn-delete-key.portrait {
		display: none;
	}

	div.area-modal div.modal-insert-ticket button.btn-delete-key.landscape {
		display: block;
		width: 92px;
		height: 40px;
		font-size: 30px;
		margin: 7px 7px;
	}

	div.area-btn-confirm-ticket {
		margin-top: 10px;
	}

	div.area-btn-confirm-ticket div button {
		width: 400px;
		height: 50px;
		font: normal normal bold 22px/24px "exo soft semi bold";
	}
}