div.head-div {
	padding:0px 15px !important;
	display:flex; align-items:center;
	align-content:center;
	height:9vh;
}

div.head-div a {
	color:#fdfdfe;
}

span.icon-suffix {padding:0px !important; margin:0px !important;}
span.icon-suffix svg {margin-left:10px; font-size:30px !important;}

.link-top-sidebar, .link-sidebar {
	transition:0.3s;
}

.link-top-sidebar:hover, .link-sidebar:hover {
	transform:translateX(5px);
	transition:0.3s;
}

.link-top-sidebar svg, .link-sidebar svg {margin-right:15px; font-size:25px;}