div.area-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}
div.area-modal div.modal-insert-cpf {
  position: relative;
  padding: 60px;
  width: 55%;
  border: 3px solid #42abad;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 5px 6px 5px -2px rgba(0, 0, 0, 0.39);
}
div.area-modal div.modal-insert-cpf label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}
div.area-modal div.modal-insert-cpf input.cpf {
  width: 100%;
  border-radius: 5px;
  color: #666666;
  margin: 15px 0px;
  border: 2px solid #666666;
  padding: 20px;
  font-size: 28px;
}
div.area-modal div.modal-insert-cpf button.btn-close-modal {
  position: absolute;
  width: 60px;
  height: 60px;
  right: -25px;
  top: -25px;
  font-size: 20px;
  background-color: #ff7575;
  border-radius: 100%;
  border: none;
  color: #ffffff;
}
div.area-modal div.modal-insert-cpf button.btn-close-modal svg {
  margin-top: -5px;
}
div.area-modal div.modal-insert-cpf button.btn-number {
  background-color: transparent;
  width: 100%;
  height: 70px;
  font-size: 40px;
  border: 3px solid #42abad;
  border-radius: 7px;
  margin: 15px 0px;
}
div.area-modal div.modal-insert-cpf button.btn-number:active {
  background-color: #42abad;
}
div.area-modal div.modal-insert-cpf button.btn-delete-key {
  background-color: #e2e2e2;
  width: 100%;
  height: 70px;
  font-size: 40px;
  border: none;
  border-radius: 7px;
  margin: 15px 0px;
}
div.area-modal div.modal-insert-cpf button.btn-delete-key svg {
  margin-top: -10px;
}
div.area-btn-confirm-cpf {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media only screen and (max-height: 900px) {
	div.area-modal div.modal-insert-cpf {
		height: 100%;
		width: 100%;
		padding: 0px 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: unset;
	}

	div.area-modal div.modal-insert-cpf label {
		font-size: 28px;
		margin-bottom: 5px;
	}

	div.area-modal div.modal-insert-cpf input.cpf {
		margin: 5px 0px;
		padding: 10px 20px;
		font-size: 22px;
	}

	div.area-modal div.modal-insert-cpf button.btn-number {
		height: 50px;
		font-size: 25px;
		margin: 5px 0px;
	}

	div.area-modal div.modal-insert-cpf button.btn-delete-key {
		height: 50px;
		font-size: 25px;
		margin: 5px 0px;
	}

	div.area-btn-confirm-cpf {
		margin-top: 10px;
	}

	div.area-btn-confirm-cpf div button {
		width: 400px;
		height: 50px;
		font: normal normal bold 22px/24px "exo soft semi bold";
	}

	div.area-modal div.modal-insert-cpf button.btn-close-modal {
		right: 2px;
		top: 2px;
	}
}
