div.container-modal-loading-payment {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  transform: translateX(55500vw);
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.2);
}
div.container-modal-loading-payment div.modal-loading-payment {
  background-color: #ffffff;
  border: 3px solid #999999;
  border-radius: 0px;
  padding: 40px 50px;
  -webkit-box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.4);
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  display: none;
}
div.container-modal-loading-payment div.modal-loading-payment label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 35px;
  margin-top: -15px;
  font-family: "exo soft medium";
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.area-img-qrcode {
  width: 100%;
  display: flex;
  justify-content: center;
}
div.container-modal-loading-payment div.modal-loading-payment span {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
div.container-modal-loading-payment div.modal-loading-payment span svg {
  animation: gira 1s linear infinite;
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment {
  float: left;
  width: 100%;
  display: none;
  justify-content: center;
  margin-top: 20px;
}
div.container-modal-loading-payment
  div.modal-loading-payment
  div.btns-modal-loading-payment
  button {
  padding: 10px 20px;
  font-size: 25px;
  margin: 0px 10px;
}

@keyframes gira {
  to {
    transform: rotate(360deg);
  }
}

div.container-modal-loading-payment div.area-read-card {
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 40px;
}
div.container-modal-loading-payment div.area-read-card label {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}

div.container-modal-loading-payment div.area-transference-pix {
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 10px 40px;
}
div.container-modal-loading-payment div.area-transference-pix label {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (max-height: 900px) {
  div.container-modal-loading-payment {
    z-index: 400;
  }
  div.container-modal-loading-payment div.modal-loading-payment {
    height: 100%;
    width: 100%;
    border: 3px solid #42abad;
  }
  div.container-modal-loading-payment div.modal-loading-payment label {
    font-size: 28px;
  }
  div.container-modal-loading-payment div.area-read-card label {
    font-size: 22px;
  }

  div.container-modal-loading-payment div.area-transference-pix label {
    font-size: 22px;
  }
}
