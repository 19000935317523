div.page-finish-payment-landscape {
  max-width: 1106px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent1;
}
div.page-finish-payment-landscape img.logo {
  width: 150px;
  height: 150px;
  margin: -15px;
}

div.page-finish-payment-landscape p.msg-purchase-successfully {
  float: left;
  width: 80%;
  max-width: 400px;
  font-size: 36px;
  text-align: center;
  margin-top: -50px;
  font-family: "exo soft bold";
  animation-duration: 1s;
  animation-name: showLeftToRightComponent4;
}

div.page-finish-payment-landscape div.image-info-wrapper {
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.page-finish-payment-landscape label.msg-obs {
  float: left;
  font-size: 24px;
  margin: 0 20px;
  font-family: "exo soft regular";
  text-align: center;
  animation-duration: 1s;
  animation-name: showLeftToRightComponent5;
}